import { Suspense, lazy } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------
// Login Page
export const LoginPage = Loadable(
  lazy(() => import("../pages/auth/login/LoginPage"))
);

//OVERVIEW PAGE
export const OverViewPage = Loadable(
  lazy(() => import("../pages/dashboard/overview/Overview"))
);

//CREATE MEAL PAGE
export const CreateMealPage = Loadable(
  lazy(() => import("../pages/dashboard/meals/CreateMeal"))
);

//MEALS PAGE
export const MealsPage = Loadable(
  lazy(() => import("../pages/dashboard/meals/Meals"))
);

//EditMeal
export const EditMeal = Loadable(
  lazy(() => import("../pages/dashboard/meals/EditMeal"))
);

//CategoriesPage
export const CategoriesPage = Loadable(
  lazy(() => import("../pages/dashboard/categories/CategoriesPage"))
);

//CreateCategoriePage
export const CreateCategoriePage = Loadable(
  lazy(() => import("../pages/dashboard/categories/CreateCategoriePage"))
);

//EditCategorie
export const EditCategorie = Loadable(
  lazy(() => import("../pages/dashboard/categories/EditCategorie"))
);

//PackagesPage
export const PackagesPage = Loadable(
  lazy(() => import("../pages/dashboard/packages/PackagesPage"))
);

//CreatePackagesPage
export const CreatePackagesPage = Loadable(
  lazy(() => import("../pages/dashboard/packages/CreatePackagesPage"))
);

//EditPackagesPage
export const EditPackagesPage = Loadable(
  lazy(() => import("../pages/dashboard/packages/EditPackagesPage"))
);


//USERS PAGE
export const UsersPage = Loadable(
  lazy(() => import("../pages/dashboard/users/users/Users"))
);

//CREATE USER
export const CreateUserPage = Loadable(
  lazy(() => import("../pages/dashboard/users/users/CreateUser"))
);

//PERMISSIONS
export const PermissionsPage = Loadable(
  lazy(() => import("../pages/dashboard/roles-and-permissions/permissions/Permissions"))
);

//ROLES
export const RolesPage = Loadable(
  lazy(() => import("../pages/dashboard/roles-and-permissions/roles/Roles"))
);



//CREATE ROLES
export const CreateRolePage = Loadable(
  lazy(() => import("../pages/dashboard/roles-and-permissions/roles/CreateRole"))
);