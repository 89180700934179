import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices

import usersReducer from "./slices/users";

import categoriesReducer from "./slices/categories";

import rolesReducer from "./slices/roles";

import generalReducer from "./slices/general";

import mealsReducer from "./slices/meals";

import packagesReducer from "./slices/packages";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const categoriesPersistConfig = {
  key: "categories",
  storage,
  keyPrefix: "redux-",
  whitelist: [""],
};

const generalPersistConfig = {
  key: "general",
  storage,
  keyPrefix: "redux-",
  whitelist: [""],
};

const rootReducer = combineReducers({
  users: usersReducer,
  categories: persistReducer(categoriesPersistConfig, categoriesReducer),
  roles: rolesReducer,
  general: persistReducer(generalPersistConfig, generalReducer),
  meals: mealsReducer,
  packages: packagesReducer,
});

export { rootPersistConfig, rootReducer };
