import { createSlice } from "@reduxjs/toolkit";

// SERVICE

import { getUsersService, getUserByIdService, createUserService, deleteUserService } from "../services/users_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingCreate: false,
  isLoadingDelete: false,
  usersList:[]
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: {

    // ==> GET USERS
    [getUsersService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.usersList = action.payload?.data?.data	;
    },
    [getUsersService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getUsersService.pending]: (state) => {
      state.isLoading = true;
    },


    // ==> GET USER BY ID
    [getUserByIdService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userInfo = action.payload;
    },
    [getUserByIdService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getUserByIdService.pending]: (state) => {
      state.isLoading = true;
    },

    // POST =>  CREATE USER
    [createUserService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;

    },
    [createUserService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createUserService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // DELETE =>  DELETE USERS

    [deleteUserService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;

    },
    [deleteUserService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteUserService.pending]: (state) => {
      state.isLoadingDelete = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { } = slice.actions;
