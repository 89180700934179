// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  login: `Login`,
  // required
  email_is_required: `Email is required`,
  password_is_required: `Password is required`,
  email_must_be_a_valid_email_address: `Email must be a valid email address`,
  phone_number: `Phone number`,
  password: `Password`,
  enter_phone_number: `Enter phone number`,
  enter_password: `Enter password`,
  en: `English`,
  ar: `Arabic`,
  username: `username`,
  front_page: `Main Page`,
  banner: `Banner`,
  view_and_control: `View and control`,
  team: `Team`,
  workflow: `Workflow`,
  customers: `Customers`,
  packages: `Packages`,
  contact_us: `Contact Us`,
  meals: `Meals`,
  logs: `Logs`,
  logout: `Logout`,
  type: `Type`,
  name_ar: `Name in arabic`,
  name_en: `Name in english`,
  add_new_meal: `Create meal`,
  edit_meal: `Edit meal`,
  meal: `Meal`,
  enter_name_ar: `Enter name in arabic`,
  enter_name_en: `Enter name in english`,
  send: `Send`,
  categories: `Categories`,
  category: `category`,
  add_new_category: `Create category`,
  edit_category: `Edit category`,
  name: `Name`,
  enter_name: `Enter Name`,
  add_package: `create package`,
  edit_package: `Edit package`,
  package: `Package`,
  image: `Image`,
  restaurant_choice: `Restaurant choice`,
  enter_restaurant_choice: `Enter Restaurant choice`,
  max_carp: `Max carp`,
  enter_max_carp: `Enter max carp`,
  max_protin: `Max protin`,
  enter_max_protin: `Enter max protin`,
  salad_count: `Salad count`,
  snack_count: `Snack count`,
  price: `Price`,
  enter_price: `Enter price`,
  enter_snack_count: `Enter snack count`,
  enter_salad_count: `Enter salad count`,
  actions: `Actions`,
  search: `Search`,
  email: `Email`,
  is_active: `is Actice`,
  role: `Role`,
  add_new_user: `Create User`,
  password_confirmation: `Password Confirmation`,
  enter_email: `Enter Email`,
  delete:`Delete`,
  done:`Done !`,
  permissions:`Permissions`,
  roles:`Roles`,
  created_at:`Created At`,
  updated_at:`Update At`,
  id:`ID`,
  create_role:`Created Role`,
  add_new_role:`Create New Role`,
  category:`Category`,
  add_new_meal:` Add New Meal`,
  quantity:`Quantity`
};

export default en;
