import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET PACKEGES
export const getPackagesService = createAsyncThunk(
    "packages/getPackagesService",
    async (thunkAPI) => {
        try {
            const response = await axios.get(URLS.packagesURL);
            return response;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);


// POST =>  CREATE PACKAGE
export const createPackageService = createAsyncThunk(
    "packages/createPackageService",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post(URLS.packagesURL, data);
            return response;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

// DELETE =>  DELETE PACKAGE
export const deletePackageService = createAsyncThunk(
    "meals/deletePackageService",
    async (id, thunkAPI) => {
        try {
            const response = await axios.delete(`${URLS.packagesURL}/${id}`);
            return response;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);