// routes
import { PATH_DASHBOARD } from "../../../routes/paths";

import SvgColor from "../../../components/svg-color";
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  // dash: icon("dash"),
};

const navConfig = [
  // Admin
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: [
      {
        title: "front_page",
        icon: ICONS.dash,
        path: "/dashboard/overview",
        index: "1",
        roles: "",
        /*children: [
          { title: "front_page", path: "/dashboard/front_page" },
          { title: "banner", path: "/dashboard/banner" },
          { title: "view_and_control", path: "/dashboard/view_and_control" },
          { title: "team", path: "/dashboard/team" },
          { title: "workflow", path: "/dashboard/workflow" },
          { title: "customers", path: "/dashboard/customers" },
          { title: "packages", path: "/dashboard/packages", },
          { title: "contact_us", path: "/dashboard/contact_us" },
        ],*/
      },

      {
        title: "users",
        icon: ICONS.dash,
        path: "/dashboard/users",
        index: "2",
        children: [
          { title: "users", path: "/dashboard/users", roles: "view_users" },
          { title: "permissions", path: "/dashboard/pemissions", roles: "view_roles" },
          { title: "roles", path: "/dashboard/roles", roles: "view_roles" },
        ],
      },
      {
        title: "customers",
        icon: ICONS.dash,
        path: "/dashboard/customers",
        index: "3",
        roles:"",
      },

      {
        title: "meals",
        icon: ICONS.dash,
        path: "/dashboard/meals",
        roles: "view_meals",
        index: "4",
      },
      //

      {
        title: "categories",
        icon: ICONS.dash,
        path: "/dashboard/categories",
        roles: "",
        index: "5",
      },

      {
        title: "packages",
        icon: ICONS.dash,
        path: "/dashboard/packages",
        roles: "view_packages",
        index: "5",
      },

      {
        title: "logs",
        icon: ICONS.dash,
        path: "/dashboard/logs",
        index: "6",
        roles:""
      },

      {
        title: "logout",
        icon: ICONS.dash,
        path: "/dashboard/logout",
        index: "7",
        roles:""
      },
    ],
  },
];

export default navConfig;
