import { createSlice } from "@reduxjs/toolkit";

import { getMealsService, createMealService, deleteMealService } from "../services/meals_service";

// ----------------------------------------------------------------------

const initialState = {
    isLoadingMeals: false,
    isLoadingCreate: false,
    isLoadingDelete: false,
    mealsList: [],
};

const slice = createSlice({
    name: "meals",
    initialState,
    reducers: {},
    extraReducers: {
        // ==> GET MEALS
        [getMealsService.fulfilled]: (state, action) => {
            state.isLoadingMeals = false;
            state.mealsList = action.payload?.data?.data?.data;
        },
        [getMealsService.rejected]: (state, action) => {
            state.isLoadingMeals = false;
        },
        [getMealsService.pending]: (state) => {
            state.isLoadingMeals = true;
        },

        // ==> CREATE MEAL
        [createMealService.fulfilled]: (state, action) => {
            state.isLoadingCreate = false;
        },
        [createMealService.rejected]: (state, action) => {
            state.isLoadingCreate = false;
        },
        [createMealService.pending]: (state) => {
            state.isLoadingCreate = true;
        },

         // ==> DELETE MEAL
         [deleteMealService.fulfilled]: (state, action) => {
            state.isLoadingDelete = false;
        },
        [deleteMealService.rejected]: (state, action) => {
            state.isLoadingDelete = false;
        },
        [deleteMealService.pending]: (state) => {
            state.isLoadingDelete = true;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { } = slice.actions;
