import { createSlice } from "@reduxjs/toolkit";

import {
  getPackagesService,
  createPackageService,
  deletePackageService,
} from "../services/packeges_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingPackages: false,
  isLoadingCreate: false,
  isLoadingDelete: false,
  packagesList: [],
  metaData:null
};

const slice = createSlice({
  name: "packages",
  initialState,
  reducers: {},
  extraReducers: {
    // ==> GET PACKEGES
    [getPackagesService.fulfilled]: (state, action) => {

      state.isLoadingPackages = false;
      state.packagesList = action.payload?.data?.data?.data;
      state.metaData=action.payload?.data?.data?.meta
    },
    [getPackagesService.rejected]: (state, action) => {
      state.isLoadingPackages = false;
    },
    [getPackagesService.pending]: (state) => {
      state.isLoadingPackages = true;
    },

    // ==> CREATE PACKAGE
    [createPackageService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPackageService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createPackageService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> DELETE PACKAGE
    [deletePackageService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deletePackageService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deletePackageService.pending]: (state) => {
      state.isLoadingDelete = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;
