import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET MEALS
export const getMealsService = createAsyncThunk(
    "meals/getMealsService",
    async (thunkAPI) => {
        try {
            const response = await axios.get(URLS.mealsURL);
            return response;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);


// POST =>  CREATE MEAL
export const createMealService = createAsyncThunk(
    "meals/createMealService",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post(URLS.mealsURL, data);
            return response;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

// DELETE =>  DELETE MEAL
export const deleteMealService = createAsyncThunk(
    "meals/deleteMealService",
    async (id, thunkAPI) => {
        try {
            const response = await axios.delete(`${URLS.mealsURL}/${id}`);
            return response;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);